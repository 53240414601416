import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private theme: BehaviorSubject <any> ;
  public darkMode: Observable <any> ;
  constructor() { 
    this.theme = new BehaviorSubject <any> (JSON.parse(localStorage.getItem('theme')) || JSON.parse(JSON.stringify({dark: false})));
    this.darkMode = this.theme.asObservable();
  }

  public get themeValue() {
    return this.theme.value;
  }

  enableDarkMode() {
   try {     
     if (JSON.parse(localStorage.getItem('theme')).dark) {
       this.toggleDarkTheme(true);
       this.theme.next({
         dark: true
       });
     } else {
       this.toggleDarkTheme(false);
       this.theme.next({
         dark: false
       });
     }
   } catch (error) {}
  }

  toggleDarkTheme(dark) {
    // Add or remove the "dark" class 
    document.body.classList.toggle('dark', dark);
    localStorage.setItem('theme', JSON.stringify({
      dark
    }));
    this.theme.next({ dark });
  }

  checkDarkMode(){
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.toggleDarkTheme(prefersDark.matches);
    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addEventListener("change", (mediaQuery) => { 
      this.toggleDarkTheme(mediaQuery.matches);
  });
  }
}
