import {
  Injectable
} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {
  Observable
} from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
    // add authorization header with jwt token if available
    const session = this.authenticationService.currentUserValue;
    // exclude urls
    const excludedUrl = [
      `${environment.apiHost}/login`, 
      'https://pentcloud-development-team.s3.amazonaws.com'];

    if (excludedUrl.some(url => request.url.indexOf(url) > -1 ? true : false)) {
      console.log('Excluded request: ' + request.url);
      return next.handle(request);
    } else {
      //session.user
        // if (session.token) {
          request = request.clone({
            setHeaders: {
              // Authorization: `Bearer ${session.token}`,
              Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJDbGllbnRlIEZhcm1hY2lhcyBCYXRyZXMiLCJyb2xlIjoiY3VzdG9tZXIifQ.h-TWAh__8Vzu10x3h5L1T8gut1N_VhordT1QQxEZUKg',
              'Content-Type': 'application/json'
            }
          });
        // }
    }

    return next.handle(request);
  }
}
