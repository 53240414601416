import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    onboarding: any = { 'feed': true, 'map': true, 'places': true, 'security': true }
    constructor(
        private http: HttpClient,
        public router: Router,
        public toastController: ToastController) {
            this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('session')));
            this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }

    login(credentials) {
      const header = new HttpHeaders();
      header.append('Content-Type', 'application/json'); // observe: 'response'
      return this.http.post<any>(`${environment.apiHost}/login`, credentials, { headers: header})
            .pipe(map((response: any) => {
                if (response && response.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('session', JSON.stringify(response));
                    localStorage.setItem('onboarding', JSON.stringify(this.onboarding));
                    this.currentUserSubject.next(response);
                }
                return response;
            }, error => {
              console.log(error);
              
            }));
    }

    async updateUserSession(user){
        if (localStorage.getItem('session')) {
            const session: any = await JSON.parse(localStorage.getItem('session'));
            session.user = user;
            localStorage.setItem('session', JSON.stringify(session));
            this.currentUserSubject.next(session);
        }
    }

    async addFCMid(fmcId){
      const session: any = await JSON.parse(localStorage.getItem('session'));
      session.user['fmcId'] = fmcId 
      localStorage.setItem('session', JSON.stringify(session));
      this.currentUserSubject.next(session);
    }
  

    auth(cred) {
        const header = new HttpHeaders();
        header.append('Content-Type', 'application/json');
        return new Promise(resolve => {
          this.http.post(`${environment.apiHost}/auth`, cred, { headers: header })
            .subscribe(
              (data: any) => { 
                if (data && data.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    data.user.countryId = data.user.country.id;
                    localStorage.setItem('session', JSON.stringify(data));
                    this.currentUserSubject.next(data);
                }
                resolve(data);
            },
             async (error) => {
                console.log(error)
                const toast = await this.toastController.create({
                    message: 'El código ingresado es incorrecto',
                    duration: 2000
                  });
                toast.present();
              }
            )
        })
      }
    
      registerFcmId(input) {
        return this.http.post(`${environment.apiURL}/clients`, input)
      }

    update(input) {
      return this.http.put(`${environment.apiURL}/clients/${input.id}`, input);
    }

    getToken(cred){
      const header = new HttpHeaders();
        header.append('Content-Type', 'application/json');
        return new Promise((resolve, reject) => {
          this.http.post(`${environment.apiHost}/token`, cred, { headers: header })
            .subscribe(
              data => { resolve(data); },
              error => { reject(error); }
            )
        })
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('session');
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
    }
}