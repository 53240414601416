import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const excludedUrl = [`${environment.apiHost}/login`];
        if (excludedUrl.some(url => url === request.url)) { 
            console.log('Excluded request: ' + request.url);
            return next.handle(request);
        } else {
        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
                const data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                return throwError(error);
            }));
        }
    }
}
